<template>
  <div class="job-category">
    <!-- toolbar -->
    <vab-query-form v-if="configuringCategory">
      <vab-query-form-left-panel :span="12">
        {{ configuringCategory.categoryName }}
      </vab-query-form-left-panel>
      <vab-query-form-right-panel :span="12">
        <el-button @click="exitSubCategory">返回</el-button>
      </vab-query-form-right-panel>
    </vab-query-form>
    <!-- 查询条件 -->
    <vab-query-form>
      <vab-query-form-left-panel :span="12">
        <el-button icon="plus" type="primary" @click="createCategory">
          添加{{ title }}
        </el-button>
      </vab-query-form-left-panel>
      <vab-query-form-right-panel :span="12">
        <el-form :inline="true" @submit.prevent="query(1)">
          <el-form-item :label="`${title}名称`">
            <el-input
              :placeholder="`请输入${title}名称`"
              v-model="formData.categoryName"
              @change="query(1)"
              clearable
            />
          </el-form-item>
          <el-form-item>
            <el-button icon="search" type="primary" @click="query()">
              查询
            </el-button>
            <el-button icon="refresh" type="warning" @click="reset">
              重置
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-right-panel>
    </vab-query-form>
    
    <!-- 表格 -->
    <el-table v-loading="loading" border :data="tableData" :table-layout="'auto'">
      <el-table-column :label="`${title}名称`" prop="categoryName" />
      <el-table-column v-if="!configuringCategory" label="子分类数量" prop="subCategoryCount" />
      <el-table-column label="展示顺序" prop="sort" />
      <el-table-column label="操作">
        <template #default="{ row }">
          <el-link type="primary" :underline="false" @click="editCategory(row)">
            编辑
          </el-link>
          <el-link v-if="!configuringCategory" type="primary" :underline="false" @click="configSubCategory(row)">
            配置子分类
          </el-link>
        </template>
      </el-table-column>
      <template #empty>
        <el-empty class="vab-data-empty" description="暂无数据" />
      </template>
    </el-table>
    
    <!-- 分页 -->
    <el-pagination
      background
      layout="prev, pager, next"
      v-model:current-page="formData.pageNum"
      :page-size="formData.pageSize"
      :total="formData.total"
      @current-change="query"
    />

    <!-- 分类编辑 -->
    <el-dialog
      v-model="categoryEditor.visible"
      :title="`${categoryEditor.type === 'add' ? '添加' : '编辑'}${title}`"
      width="500px"
      @close="handleCategoryEditorClose"
    >
      <el-form v-if="categoryEditor.data" :label-width="80">
        <el-form-item :label="`${title}名称`" prop="categoryName">
          <el-input
            v-model="categoryEditor.data.categoryName"
            :placeholder="`请输入${title}名称`"
            maxlength="50"
            show-word-limit
          />
        </el-form-item>
        <el-form-item v-if="categoryEditor.type === 'edit'" label="展示顺序" prop="sort">
          <el-input-number
            v-model="categoryEditor.data.sort"
            placeholder="请输入展示顺序"
            controls-position="right"
            :min="0"
          />
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="handleCategoryEditorClose">取消</el-button>
        <el-button type="primary" @click="handleCategoryEditorConfirm">确定</el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
import { ref, reactive, computed, onMounted, getCurrentInstance } from 'vue'
import { getCategoryList, addCategory, updateCategory } from '@/api/recruitment'
import { omit } from 'lodash'

const { proxy } = getCurrentInstance()

const formData = reactive({
  categoryName: '',
	pageNum: 1,
	pageSize: 20,
  total: 0
})

const loading = ref(false)
const tableData = ref([])
const configuringCategory = ref(null)
const cachedCategoryList = ref([])
const cachedFormData = ref(null)
const cachedPageNum = ref(1)

const title = computed(() => (configuringCategory.value ? '子' : '') + '分类')

const categoryEditor = reactive({
  type: 'add',
  visible: false,
  data: null,
})

onMounted(() => {
  query()
})

function query(pageNum = formData.pageNum) {
  const params = {
    ...omit(formData, 'total'),
    pageNum
  }

  if (configuringCategory.value) {
    params.parentId = configuringCategory.value.id
  }

  loading.value = true
  
  getCategoryList(params).then(({code, data}) => {
    if (code !== 200) return
    const {list, total} = data;
    tableData.value = list
    formData.total = total
  }).finally(() => {
    loading.value = false
  })
}

function reset() {
  formData.categoryName = ''
  query(1)
}

function createCategory() {
  categoryEditor.type = 'add'
  categoryEditor.visible = true
  categoryEditor.data = {
    sort: 0,
    categoryName: '',
  }
}

function editCategory(row) {
  categoryEditor.type = 'edit'
  categoryEditor.visible = true
  categoryEditor.data = {...row}
}

function configSubCategory(row) {
  configuringCategory.value = row
  cachedCategoryList.value = tableData.value
  cachedFormData.value = {...formData}
  cachedPageNum.value = formData.pageNum
  formData.categoryName = ''
  query(1)
}

function exitSubCategory() {
  configuringCategory.value = null
  tableData.value = cachedCategoryList.value
  cachedCategoryList.value = []
  formData.categoryName = cachedFormData.value.categoryName
  cachedFormData.value = null
  formData.pageNum = cachedPageNum.value
  query()
}

function handleCategoryEditorClose() {
  categoryEditor.visible = false
  categoryEditor.data = null
}

function handleCategoryEditorConfirm() {
  const {type, data} = categoryEditor
  if (configuringCategory.value) {
    data.parentId = configuringCategory.value.id
  }
  
  if (data.categoryName.trim() === '') {
    proxy.$baseMessage(`分类名称不能为空`, 'error', 'vab-hey-message-error')
    return
  }
  
  if (type === 'add') {
    addCategory(data).then(({code}) => {
      if (code !== 200) return
      proxy.$baseMessage(`操作成功`, 'success', 'vab-hey-message-success')
      query(1)
      handleCategoryEditorClose()
    })
  } else if (type === 'edit') {
    updateCategory(data).then(({code}) => {
      if (code !== 200) return
      proxy.$baseMessage(`操作成功`, 'success', 'vab-hey-message-success')
      query()
      handleCategoryEditorClose()
    })
  } else {
    handleCategoryEditorClose()
  }
}
</script>

<style lang="scss" scoped>
.job-category {
  padding: 16px;
}
</style>
