<!--
* @description:
* @fileName publish.vue
* @author hvv
* @date 2022/01/13 14:42:27
!-->
<template>
  <div>
    <el-card shadow="always" class="filterContainer">
      <el-form :inline="true" :model="formInline" class="demo-form-inline">
        <el-form-item label="活动名称">
          <el-input
            v-model="formInline.user"
            placeholder="Approved by"
          ></el-input>
        </el-form-item>
        <el-form-item label="类型">
          <el-select v-model="formInline.region" placeholder="Activity zone">
            <el-option label="Zone one" value="shanghai"></el-option>
            <el-option label="Zone two" value="beijing"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">筛选</el-button>
        </el-form-item>
      </el-form>
    </el-card>

    <!-- table -->
    <el-card shadow="always" class="tableContainer">
      <el-table :data="tableData" style="width: 100%">
        <el-table-column fixed prop="time" label="时间" width="350" />
        <el-table-column prop="name" label="活动名称" width="120" />
        <el-table-column prop="address" label="地址" width="120" />
        <el-table-column prop="poster" label="海报" width="120" />
        <el-table-column prop="count" label="人数" width="120" />
        <el-table-column prop="type" label="类型" width="120" />
        <el-table-column prop="liveUrl" label="直播地址" width="160" />
        <el-table-column fixed="right" label="编辑" width="300" align="center">
          <template #default>
            <el-button type="text" size="small" @click="drawer = true">
              查 看
            </el-button>
            <el-button type="text" size="small">编辑</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>

    <!-- 查看组件 -->
    <el-drawer v-model="drawer" title="活动详情" :direction="direction">
      <span>Hi, there!</span>
    </el-drawer>
  </div>
</template>

<script>
  import {
    defineComponent,
    getCurrentInstance,
    onMounted,
    reactive,
    toRefs,
  } from 'vue'
  export default defineComponent({
    name: 'publish',
    setup() {
      const tableData = [
        {
          name: '活动名称',
          time: '2022-3-24 12:00:00 - 2022-3-24 14:00:00',
          address: '杭州博物馆',
          poster: '我是封面',
          type: '类型',
          count: '100人',
          liveUrl: 'https://www.live.com',
        },
        {
          name: '活动名称',
          time: '2022-3-24 12:00:00 - 2022-3-24 14:00:00',
          address: '杭州博物馆',
          poster: '我是封面',
          type: '类型',
          count: '100人',
          liveUrl: 'https://www.live.com',
        },
        {
          name: '活动名称',
          time: '2022-3-24 12:00:00 - 2022-3-24 14:00:00',
          address: '杭州博物馆',
          poster: '我是封面',
          type: '类型',
          count: '100人',
          liveUrl: 'https://www.live.com',
        },
        {
          name: '活动名称',
          time: '2022-3-24 12:00:00 - 2022-3-24 14:00:00',
          address: '杭州博物馆',
          poster: '我是封面',
          type: '类型',
          count: '100人',
          liveUrl: 'https://www.live.com',
        },
      ]
      const state = reactive({
        formInline: {
          user: '',
          region: '',
        },
        tableData,
        drawer: false,
        direction: 'rtl',
      })
      const onSubmit = () => {}

      return {
        ...toRefs(state),
        onSubmit,
      }
    },
  })
</script>
<style lang="scss" scoped>
  .filterContainer {
    padding: 10px;
    box-shadow: #ccc;
  }

  .tableContainer {
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 20px;
    background: #fff;
  }
</style>
